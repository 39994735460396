import React, { createContext, useState, useContext, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

// Create a context
const ConfirmContext = createContext();

// ConfirmProvider Component
export const ConfirmProvider = ({ children }) => {
  const [confirmState, setConfirmState] = useState({
    open: false,
    message: '',
    onConfirm: null,
    onCancel: null,
    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel',
    dialogStyle: {},
    confirmButtonStyle: {},
    cancelButtonStyle: {},
  });

  const confirm = useCallback(
    ({ message, onConfirm, onCancel, confirmButtonText, cancelButtonText, dialogStyle, confirmButtonStyle, cancelButtonStyle }) => {
      return new Promise((resolve, reject) => {
        setConfirmState({
          open: true,
          message,
          onConfirm: () => {
            if (onConfirm) onConfirm();
            resolve(true);
            setConfirmState((prev) => ({ ...prev, open: false }));
          },
          onCancel: () => {
            if (onCancel) onCancel();
            reject(false);
            setConfirmState((prev) => ({ ...prev, open: false }));
          },
          confirmButtonText: confirmButtonText || 'Confirm',
          cancelButtonText: cancelButtonText || 'Cancel',
          dialogStyle: dialogStyle || {},
          confirmButtonStyle: confirmButtonStyle || {},
          cancelButtonStyle: cancelButtonStyle || {},
        });
      });
    },
    []
  );

  return (
    <ConfirmContext.Provider value={confirm}>
      {children}
      <Dialog open={confirmState.open} onClose={confirmState.onCancel} style={confirmState.dialogStyle}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>{confirmState.message}</DialogContent>
        <DialogActions>
          <Button
            onClick={confirmState.onCancel}
            // variant="outlined"
            style={confirmState.cancelButtonStyle}
          >
            {confirmState.cancelButtonText}
          </Button>
          <Button
            onClick={confirmState.onConfirm}
            style={confirmState.confirmButtonStyle}
          >
            {confirmState.confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmContext.Provider>
  );
};

// Custom Hook
export const useConfirm = () => {
  const context = useContext(ConfirmContext);
  if (!context) {
    throw new Error('useConfirm must be used within a ConfirmProvider');
  }
  return context;
};

export default ConfirmProvider;