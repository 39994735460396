import React from 'react';

import ReduxProvider from 'providers/ReduxProvider';
import ThemeProvider from 'providers/ThemeProvider';
import RoutesProvider from 'providers/RoutesProvider';
import AuthStateListener from 'providers/AuthStateListener';
import ConfirmProvider from 'providers/ConfirmProvider';
import { SnackbarProvider } from "notistack";

function ThemedComponent() {
  return (
    <ReduxProvider>
      <ThemeProvider>
        <SnackbarProvider>
          <AuthStateListener>
            <ConfirmProvider>
              <RoutesProvider />
            </ConfirmProvider>
          </AuthStateListener>
        </SnackbarProvider>
      </ThemeProvider>
    </ReduxProvider>
  );
}

export default ThemedComponent;
