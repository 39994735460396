import { map, values, filter, includes} from 'lodash';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { 
  Box, 
  Typography, 
  Button, 
  CircularProgress, 
  Paper, 
  Container, 
  Chip,
  Tooltip,
  useTheme,
  alpha,
  styled,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DateRangePickerFilter from './DateRangePickerFilter';
import dayjs from 'dayjs';
import DescriptionIcon from '@mui/icons-material/Description';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { signOut } from 'firebase/auth';
import { ref, push, onValue, get, child, off } from 'firebase/database';
import { useSnackbar } from 'notistack';


import { useConfirm }  from 'providers/ConfirmProvider';

import { auth, db } from '../firebase/config';
import { clearUser } from '../store/authSlice';

// Styled component for the main title
const MainTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto Slab", "Helvetica", "Arial", sans-serif',
  fontWeight: 400,
  fontSize: '2rem',
  color: theme.palette.primary.main,
  // marginBottom: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));


function Home() {
  const theme = useTheme();
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [zipLoading, setZipLoading] = useState({});
  const [zipLinks, setZipLinks] = useState({})
  
  const handleSignOut = async () => {
    try {
      signOut(auth)
      dispatch(clearUser())
    navigate('/home');
        } catch (error) {
          console.error('Error signing in with email link:', error);
          setError('Failed to sign in. Please try again.');
        } 
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const userItemsRef = ref(db, 'userItems');
        const itemsRef = ref(db, 'items');
        const items = await get(child(userItemsRef, user.uid)).then(s=>s.val());
        const allItems = await Promise.all(map(items, async (item, itemId) => {
          const itemData = await get(child(itemsRef, itemId)).then(s=>s.val());
          return { ...itemData, date: itemData.cocDates ? values(itemData.cocDates)[0] : '2023-01-01', id: itemId }
        }))
        setRows(allItems);
      } catch (err) {
        setError('Failed to fetch data. Please try again later.');
        enqueueSnackbar(`Error fetching data: ${error}`, { variant : 'error'});
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [user?.uid]);
  
  useEffect(() => {
    const setListener = () => {
      map(zipLoading, (id, docType) => {
        onValue(ref(db, `zipUrls/${id}`), snapshot => {
          if (snapshot.exists()) {
            const response = snapshot.val();
            if (response.error) {
              enqueueSnackbar(`Error creating .zip file: ${response.error}`, {variant: 'warning'})
            } else {
              setZipLinks(prev => ({ ...prev, [docType]: response}));
            }
          setZipLoading(prev => ({ ...prev, [docType]: null }));
            off(ref(db, `zipUrls/${id}`));
            enqueueSnackbar('Zip file created.  Please click the button again to download', 
            {variant: 'success'})
          }
      })
      })
    }
    setListener()
  }, [zipLoading])
  
  const handleZipCreation = useCallback((ids, docType) => {
    const selectedRowData = filter(rows, row => includes(ids, row.id));
    const dataBooks = map(selectedRowData, data => data[docType]?.[data.jobId] || null);
    const newRef = push(ref(db, `zipFiles`), dataBooks)
    setZipLoading(prev => ({ ...prev, [docType]: newRef.key }));
  }, [rows])
  
  

  const handleCOCs = useCallback((ids) => {
    handleZipCreation(ids, 'cocs')
  }, [handleZipCreation]);

  const handleDatabooks = useCallback(async (ids) => {
    try {
      await confirm({
        message: 'Creating .zip for databooks could result in errors due to cumulative file size.  Should this happen please refresh the page and download items individually using the download links below.  Do you want to proceed?',
        confirmButtonText: 'Yes, proceed',
        cancelButtonText: 'No, cancel',
        dialogStyle: { color: 'darkblue' },
        confirmButtonStyle: { backgroundColor: theme.palette.success.main, color: theme.palette.success.contrastText },
        cancelButtonStyle: { backgroundColor: theme.palette.error.light, color: theme.palette.success.contrastText },
      });
      handleZipCreation(ids, 'dataPackets')
    } catch (error) {
      console.log(error)
      console.log('User canceled');
    }
    
  }, [handleZipCreation]);

  const columns = useMemo(() => [
    { field: 'asset', headerName: 'Asset', width: 130 },
    { field: 'description', headerName: 'Description', width: 200 },
    { 
      field: 'date', 
      headerName: 'Date', 
      width: 130, 
      type: 'date',
      valueGetter: (value) => dayjs(value).toDate(),
      filterOperators: [
        {
          label: 'Date Range',
          value: 'dateRange',
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value || !filterItem.value.startDate || !filterItem.value.endDate) {
              return null;
            }
            const { startDate, endDate } = filterItem.value;
            return ({ value }) => {
              const date = dayjs(value);
              return date.isAfter(startDate) && date.isBefore(endDate);
            };
          },
          InputComponent: DateRangePickerFilter,
        },
      ],
    },
    { field: 'workOrder', headerName: 'Job', width: 130 },
    {
      field: 'actions',
      headerName: 'Download',
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Download COC">
            <Button 
              onClick={() => handleCOCs([params.row.id])} 
              variant="contained" 
              size="small" 
              sx={{ mr: 1, minWidth: 'unset' }}
              disabled={rowSelectionModel.length > 1 || !params.row.cocs}
              component='a'
              href={values(params.row.cocs)[0]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DescriptionIcon fontSize="small" />
            </Button>
          </Tooltip>
          <Tooltip title="Download Databook">
            <Button 
              onClick={() => handleDatabooks([params.row.id])} 
              variant="outlined" 
              size="small"
              sx={{ minWidth: 'unset' }}
              disabled={rowSelectionModel.length > 1|| !params.row.dataPackets}
              component='a'
              href={values(params.row.dataPackets)[0]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <CloudDownloadIcon fontSize="small" />
            </Button>
          </Tooltip>
        </Box>
      ),
    },
  ], [rowSelectionModel, handleCOCs, handleDatabooks]);

  const handleRowSelectionModelChange = useCallback((newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel);
    setZipLinks({});
    setZipLoading({});
  }, []);

  if (loading) return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </Box>
  );
  
  if (error) return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Typography color="error">{error}</Typography>
    </Box>
  );

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, mt: 4, borderRadius: 2 }}>
        <MainTitle variant="h1">
          Gulf-Pro Services Customer Portal
        </MainTitle>
        
        <Typography variant="subtitle1" gutterBottom color="text.secondary" 
        sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          {user ? `Logged in as: ${user.email}` : 'Not logged in'} 
          <Button onClick={handleSignOut}>Sign Out</Button>
        </Typography>
        {rowSelectionModel.length > 1 && (
          <Paper sx={{ mb: 2, p: 2, bgcolor: theme.palette.background.default, borderRadius: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="subtitle1">
                <Chip label={`${rowSelectionModel.length} items selected`} color="primary" />
              </Typography>
              <Box>
                <Button 
                  onClick={() => {
                  if (!Boolean(zipLinks['cocs'])) { 
                    handleCOCs(rowSelectionModel)
                  }
                  }} 
                  variant="contained" 
                  size="small" 
                  sx={{ mr: 1 }}
                  disabled={Boolean(zipLoading['cocs'])}
                  startIcon={Boolean(zipLoading['cocs']) ? <CircularProgress size={18}/> : <DescriptionIcon />}
                  component={Boolean(zipLinks['cocs']) ? 'a' : 'button'}
                  href={zipLinks['cocs'] || ''}
                >
                  {Boolean(zipLinks['cocs']) ? 'Download COC Zip File' : "Create COC Zip File"}
                </Button>
                <Button 
                  onClick={() => {
                   if (!Boolean(zipLinks['dataPackets'])) {
                    handleDatabooks(rowSelectionModel);
                   }
                  }}
                  variant="outlined" 
                  size="small"
                  disabled={Boolean(zipLoading['dataPackets'])}
                  startIcon={Boolean(zipLoading['dataPackets']) ? <CircularProgress size={18}/> : <CloudDownloadIcon />}
                  component={Boolean(zipLinks['dataPackets']) ? 'a' : 'button'}
                  href={zipLinks['dataPackets'] || ''}
                >
                  {Boolean(zipLinks['dataPackets']) ? 'Download Databooks Zip File' : "Create Databooks Zip File"}
                </Button>
              </Box>
            </Box>
          </Paper>
        )}
        <Box sx={{ height: 'calc(87vh - 60px)', width: '100%' }}>
          <DataGrid
            rows={rows}
            getRowId={r => r.itemId}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 50, page: 0 },
              },
              sorting: {
                sortModel: [{field: 'date', sort: 'desc'}]
              },
              density: 'compact',
            }}
            pageSizeOptions={[20, 50, 100]}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={handleRowSelectionModelChange}
            rowSelectionModel={rowSelectionModel}
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            sx={{
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
              '& .MuiDataGrid-columnHeader': {
                // backgroundColor: alpha(theme.palette.primary.main, 0.8),
                // color: theme.palette.primary.contrastText,
                fontSize: '1rem',
                fontWeight: 'bold',
                '&:focus, &:focus-within': {
                  outline: 'none',
                },
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
              },
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-sortIcon': {
                color: theme.palette.primary.contrastText,
              },
              '& .MuiDataGrid-menuIcon': {
                color: theme.palette.primary.contrastText,
              },
              '& .MuiDataGrid-toolbarContainer': {
                backgroundColor: alpha(theme.palette.primary.main, 0.1),
                padding: theme.spacing(1),
              },
            }}
          />
        </Box>
      </Paper>
    </Container>
  );
}

export default Home;